<template>
  <section class="edit-category">
    
    <div class="page-header mb-3">
      <h3 class="page-title">Edit Brand</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/view-brand" class="text-primary">Brand
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Brand</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="editBrand">
      <div class="row" v-if="edit_brand">

        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="edit_brand.name" id="name" name="name" class="form-control" :class="{
                    'is-invalid':
                      submitted && $v.edit_brand.name.$error,
                  }" />
                  <div v-if="submitted && !$v.edit_brand.name.required" class="invalid-feedback">
                    Name is required
                  </div>
                </div>
              </div>

              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                  </label>
                  <div class="form-group">
                    <textarea class="form-control" rows="8" v-model="edit_brand.description"></textarea>
                  </div>

                </div>
              </div>
              <div class="form-group  ">
                <label for=""> Status </label>
                <div class="form-group  ">
                  <toggle-button :value="true" v-model="edit_brand.active"
                    :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">

              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Sort </label>

                <div class="form-group">
                  <input type="number" v-model="edit_brand.sort" class="form-control" />
                </div>

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Image
                  </label>
                  <div class="form-group">
                    <div class="form-group">
                      <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>

                      <chooseImage @imageId="setImageId"></chooseImage>
                      <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions" :useCustomSlot="true"
                        v-on:vdropzone-success="success" @vdropzone-sending="sendImages">
                        <div class="dropzone-custom-content">
                          <div class="dropzone-custom-content">
                            <div v-if="bannerImg">
                              <div class="image-size-dropzone">
                                <img :src="`${imageApiURL}/${bannerImg.path}`" class="show-img img-fluid" />
                              </div>
                              <div class="subtitle pt-1 pb-0">
                                <span> Change </span>
                                <span style="color: #eb2027"> Browse</span>
                              </div>
                            </div>
                            <div v-if="!bannerImg" class="pt-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                                fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-upload" color="">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                              <div class="subtitle pt-1 pb-5">
                                <span> Drop File or </span>
                                <span style="color: #eb2027"> Browse</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-group  ">
                <label for=""> Show home </label>
                <div class="form-group  ">
                  <toggle-button :value="false" v-model="edit_brand.show_home"
                    :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Update</button>
          </div>
        </div>

      </div>
    </form>


  </section>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import { catalogApi } from "../../../api";

import { required } from "vuelidate/lib/validators";
import chooseImage from "../../../components/chooseImage";
export default {
  name: "edit_brand",
  props: {
    edit: {
      active: Boolean,
      sort: Number,
      name: String,
      description: String,
      image_id: Number,
      show_home: Number,
    },
  },
  components: {
     chooseImage,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 1,
      },
      edit_brand: this.edit,
      info: "",
      submitted: false,
      
      imageApiURL,
      bannerImg: "",
    };
  },
  validations: {
    edit_brand: {
      name: { required },
    },
  },
  mounted() {
    this.getStoreById();
  },
  methods: {
    async getStoreById() {
      
      const { data } = await catalogApi.getBrandById(this.$route.params.id);
      this.edit_brand = data.data.data;
      this.edit_brand.forEach((value) => {
        this.edit_brand = value;
        this.edit_brand = value;
        if (this.edit_brand.image_id) {
          this.categoryImageById();
        }
        if (this.edit_brand.show_home === 1) {
          this.edit_brand.show_home = true;
        } else {
          this.edit_brand.show_home = false;
        }
        if (this.edit_brand.active === 1) {
          this.edit_brand.active = true;
        } else {
          this.edit_brand.active = false;
        }
      });
      
    },
    categoryImageById() {
      
      axios
        .get(`${mediaApi}/files/${this.edit_brand.image_id}`)
        .then((response) => {
          this.bannerImg = response.data.data[0];
          
        });
    },
    sendImages(file, xhr, formData) {
      let brands = "brands";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", brands);
    },
    setImageId(selectedId) {
      
      axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
        this.bannerImg = response.data.data[0];
        
      });
    },
    success(file, response) {
      this.edit_brand.image_id = response.data.id;
      this.bannerImg = ""
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async editBrand() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.edit_brand.active === true) {
        this.edit_brand.active = 1;
      } else {
        this.edit_brand.active = 0;
      }

      if (this.bannerImg) {
        this.edit_brand.image_id = this.bannerImg.id
      }
      if (this.edit_brand.show_home === true) {
        this.edit_brand.show_home = 1;
      } else {
        this.edit_brand.show_home = 0;
      }

      for (var key in this.edit_brand) {
        if (this.edit_brand.hasOwnProperty(key)) {
          var val = this.edit_brand[key];
          if (val === null) {
            delete this.edit_brand[key];
          }
        }
      }

      
      const data = await catalogApi.updateBrand(this.$route.params.id, {
        ...this.edit_brand,
      });
      
      this.$router.push("/catalog/view-brand");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 8px 20px !important;
}

.form-group label {
  text-align: right;
}

.form-group label span {
  font-size: 10px;
}
</style>